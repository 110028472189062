import React from "react"
import "./styles/Advertise.scss"
import { Router } from "@reach/router"
import AdvertHome from "../components/Advert/AdvertHome"

function Advert() {

  return (
      <Router>
        <AdvertHome path="adverteren" />
      </Router>
  )
}

export default Advert
