import React, { useState, useEffect, useMemo } from "react"
import "./Advertise.scss"
import { useGuestApi } from "../../hooks/useApi"
import Title from "../Text/Title"
import MainContent from "../Layout/MainContent"
import BodyContent from "../Text/BodyContent"
import withGuestLayout from "../../hoc/withGuestLayout"
import {
  getEmail,
  getName,
  getSingleImage,
  getTitle,
} from "../../helpers/Publications"
import { returnLayoutSection } from "../../helpers/Layout"
import { imageUrl } from "../../helpers/ImageUrl"
import { getImageType } from "../../helpers/Prepr"

function AdvertHome() {
  const { getAdvertPublication } = useGuestApi()
  const [content, setContent] = useState(null)
  const accountContacts = useMemo(
    () => content?.account_contacts?.items || [],
    [content]
  )

  useEffect(() => {
    getAdvertPublication().then(response => {
      setContent(response)
    })
  }, [getAdvertPublication])

  if (!content) {
    return null
  }

  return (
    <MainContent
      {...content}
      headerWidth={960} headerHeight={312}
    >
      <Title>{getTitle(content.title)}</Title>
      <BodyContent>
        {content.layout
          ? Array.isArray(content.layout.items)
            ? content.layout.items.map((item, i) => {
                return returnLayoutSection(item, i)
              })
            : null
          : null}
        <div className="advertise">
          {accountContacts.map((contact, index) => {
            const item = contact?.items["nl-NL"]
            return (
              <div key={index} className="advertise--person">
                <img
                  src={imageUrl(
                    getSingleImage(
                      getImageType(
                        "image_website_block",
                        item?.image,
                        null,
                        null,
                        null
                      )
                    )
                  )}
                  alt={getName(item?.name)}
                  className="advertise--person--image"
                />
                <div className="advertise--person--info">
                  <p>
                    {getTitle(item?.title)}
                    <br />
                    <a href={`mailto:${getEmail(item?.email)}`}>
                      {getEmail(item?.email)}
                    </a>
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </BodyContent>
    </MainContent>
  )
}

export default withGuestLayout(AdvertHome, {
  page: "Adverteren",
})
